<template>
    <div id="pdlWrap">
        <loader v-show="loading"></loader>
        <div v-show="!loading">
            <div class="layoutBorder" v-if="formStatus == 0 && formStep >= 0" v-bind:class="{readOnly:formStep > 0}" ref="formAlign">
				<div class="layoutHeader">
					<h4>Получить займ</h4>
					<p v-if="formErrMsg.length > 0" class="formGlobalErr">{{formErrMsg}}</p>
				</div>
				<div class="layoutBody">
					<div id="formStep1">
						<div class="subBox">
							<div>
								<p>Выберите период:</p>
								<div id="pdlPeriod">
									<div>
										<label> <input type="radio" v-model="forSend.ProductType" value="O" :disabled="ProductType_O ? undefined : 'disabled' " > Одна неделя</label>
									</div>
									<div>
										<label> <input type="radio" v-model="forSend.ProductType" value="T" :disabled="ProductType_T ? undefined : 'disabled' " >  Две недели</label>
									</div>
									<div>
										<label> <input type="radio" v-model="forSend.ProductType" value="P" :disabled="ProductType_P ? undefined : 'disabled' ">  Месяц</label>
									</div>
								</div>
							</div>
							<div>
								<p>Выберите сумму займа: <span class="bigNumber">{{forSend.selectedSumm | moneyIntlFormat}}</span></p>
								<range :rangeMin="minLimit" :rangeMax="pdlLimit" :rangeStep="1000" v-model="forSend.selectedSumm" ref="range" ></range>
								<div class="limitRange">
									<p>От {{minLimit | moneyIntlFormat}}</p>
									<p>до {{pdlLimit | moneyIntlFormat}}</p>
								</div>
							</div>
						</div>
						<div class="subBox">
							<p>Ваш платеж составит:</p>
							<p class="bigNumber">{{computedPay.FixedPay | moneyIntlFormat}}</p>
						</div>
					</div>
				</div>
				<div class="layoutFooter btnIsset" v-if="formStep == 0"> <button class="submitButton" v-on:click.prevent="fromStep(0)">Продолжить</button> </div>
			</div>

			<!--div class="layoutBorder"
                 v-if="formStatus == 0 && formStep >= 4"
                 ref="formAlign"
                 v-bind:class="{readOnly:formStep > 4}"
            -->
			<!--div class="layoutBorder" v-if="formStep == 3"  v-bind:class="{readOnly:formStep > 0}" -->
			<div class="layoutBorder" v-if="formStatus == 0 && formStep >= 1"  v-bind:class="{readOnly:formStep > 1}" >
				<div class="layoutHeader">
					<h4>Укажите реквизиты банковского счета</h4>
					<p v-if="formErrMsg.length > 0" class="formGlobalErr">{{formErrMsg}}</p>
				</div>
				<div class="layoutBody">
					<div class="inputBox">
						<div class="autosuggest-container">
							<vue-autosuggest
							  class="inputBox"
							  v-model="forSend.bankInfo.value"
							  :suggestions="suggestions"
							  @selected="onSelected"
							  :get-suggestion-value="getSuggestionValue"
							  :input-props="{id:'autosuggest__input'}">
								<div slot-scope="{suggestion}" class="sugsItem">
									<p>{{suggestion.item.value}}</p>
									<p>{{suggestion.item.data.bic}}, {{suggestion.item.data.address.value}}</p>
								</div>
							</vue-autosuggest>
						</div>
						<label>Банк</label>
						<!--                    <div v-show="ErrorSelected.length > 0" v-html="ErrorSelected" class="errMsg"></div>-->
						<i class="stxt">Введите название, БИК, SWIFT или ИНН, и выберите из выпадающего списка</i>
					</div>
					<div id="BankAlign">
						<div>
							<baseInput
							  :label="'БИК'"
							  v-model="forSend.bankInfo.data.bic"
							  :errMsg="err.bic"
							></baseInput>
							<!--baseInput
							  :label="'ИНН'"
							  v-model="forSend.bankInfo.data.inn"
							  :errMsg="err.Inn"
							></baseInput-->
						</div>
						<!--div>
							<baseInput
							  :label="'КПП'"
							  v-model="forSend.bankInfo.data.kpp"
							  :errMsg="err.kpp"
							></baseInput>
							<baseInput
							  :label="'Корреспондентский счет в ЦБ РФ'"
							  v-model="forSend.bankInfo.data.correspondent_account"
							  :errMsg="err.correspondent_account"
							></baseInput>
						</div-->
						<div>
							<baseInput
							  :label="'Номер счета'"
							  v-model="forSend.bankInfo.AccountNumber"
							  :errMsg="err.AccountNumber"
							></baseInput>
							<p>Получатель платежа: {{forSend.FIO}}</p>
						</div>
					</div>
				</div>
				<div class="layoutFooter btnIsset">
					<button class="submitButton" v-on:click.prevent="fromStep(1)" v-if="formStep == 1">Продолжить оформление</button>
				</div>
			</div>
			<div class="layoutBorder" v-if="formStatus == 0 && formStep >= 2"  v-bind:class="{readOnly:formStep > 2}">
				<div class="layoutHeader"> <h4>Подписание договора</h4> </div>
				<div class="layoutBody">
					<div id="dealInfo">
						<div>
							<p>{{dealInfo.paymentDate | timeFromXtoDateView}}</p>
							<p>Дата платежа</p>
						</div>
						<div>
							<p>{{dealInfo.paymentTerm}} дней</p>
							<p>Срок займа</p>
						</div>
						<div>
							<p>{{forSend.selectedSumm | moneyIntlFormat}}</p>
							<p>Сумма займа</p>
						</div>
					</div>
					<div id="isAgree">
						<div>
							<input type="checkbox" v-model="dealInfo.isAgree">
							<p v-bind:class="{errMsg:!dealInfo.isAgree}">Я согласен/на и ознакомлен/на со следующим:</p>
						</div>
						<div>
							<input type="checkbox" v-model="dealInfo.isGeneralConditions">
							<p>Общие условия договора микрозайма с ООО МКК ФИНМОЛЛ</p>
						</div>
						<a
						  href="https://finmoll.ru/documents/2021.03.23_%D0%BE%D0%B1%D1%89%D0%B8%D0%B5_%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F_%D0%B4%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%D0%B0_%D0%BC%D0%B8%D0%BA%D1%80%D0%BE%D0%B7%D0%B0%D0%B9%D0%BC%D0%B0.pdf"
						  target="_blank">Открыть документ</a>
						<div>
							<input type="checkbox"
								   v-model="dealInfo.isTermsOfProvision">
							<p>Правила предоставления микрозаймов ООО МКК ФИНМОЛЛ</p>
						</div>
						<a
						  href="https://finmoll.ru/documents/2021.02.12_%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0_%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F_%D0%BC%D0%B8%D0%BA%D1%80%D0%BE%D0%B7%D0%B0%D0%B9%D0%BC%D0%BE%D0%B2.pdf"
						  target="_blank">Открыть документ</a><br/>
						<div>
							<input type="checkbox" v-model="dealInfo.isAgreeRequest">
							<p>Заявление на предоставление займа</p>
						</div>
						<a :href="'/public/pdlForm?request=' + GUUID" target="_blank">Открыть документ</a>
						<div>
							<input type="checkbox" v-model="dealInfo.isAgreeIU">
							<p>Индивидуальные условия</p>
						</div>
						<a :href="'/public/pdl_IU?request=' + GUUID" target="_blank">Открыть документ</a>
					</div>
				</div>
				<div class="layoutFooter btnIsset">
					<div id="confCodeWrapAlign">
						<div id="confCodeWrap">
							<p>Введите код из смс</p>
							<baseInput :label="'Код подтверждения'" v-model="dealInfo.smsConfCode" ></baseInput>
							<span style="color: #0000ee; text-decoration: underline; cursor: pointer;" v-on:click.prevent="resendingSms">Выслать код повторно</span>
						</div>
					</div>
					<button class="submitButton" v-on:click.prevent="finishPdl" v-bind:class="{active:!dealInfo.isAgree}"  v-if="formStep == 2">Подписать договор</button>
				</div>
			</div>
			<div class="layoutBorder" v-if="formStep == 3">
				<div class="layoutHeader">
					<h4>Результат</h4>
				</div>
				<div class="layoutBody">
					<div style="text-align: center; padding: 2em;">
						<h2>Поздравляем!</h2>
						<p>Договор успешно пройден</p>
					</div>
					<div id="dealInfo" style="width: 40%;">
						<div>
							<p>{{dealInfo.paymentDate | timeFromXtoDateView}}</p>
							<p>Дата платежа</p>
						</div>
						<div>
							<p>{{forSend.selectedSumm | moneyIntlFormat}}</p>
							<p>Сумма займа</p>
						</div>
					</div>
				</div>
				<div class="layoutFooter btnIsset">
					<button class="submitButton" v-on:click.prevent="$router.push('/')">ВЕРНУТЬСЯ НА ГЛАВНУЮ</button>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
    import {VueAutosuggest} from "vue-autosuggest";
export default {
    name: "loanEnding",
    components:{
        rangePeriod: () => import('@/views/pdl/rangePeriod'),
        fmSelectRef: () => import('@/components/Form/element/fmSelectRef'),
        baseInput: () => import('@/components/Form/element/baseinput'),
        imageInput: () => import('@/components/formElement/imageInput'),
        loader: () => import('@/components/other/loader'),
        range: () => import('@/components/Form/element/range'),
        fmDadataBank: () => import('@/components/Form/element/fmSelectDadataBank'),
        checkBox:()=>import('@/components/Form/element/CheckBox'),
        VueAutosuggest
    },
    data() {
        return {
			isDebug:true,
            forSend: {
                insurance: false,
                EDUCATION: '',
                EXPER_RADIO: '',
                TYPE_OF_ORGANIZATION: "Государственная", // 2
                TYPE_OF_ACTIVITY: "Сфера услуг",         // 2
                TYPE_OF_EMPLOYMENT: "Пенсионер",         // 4
                MARITAL_STATUS: '',
                WHERE_T_FIND_US: "Рекламная рассылка",   // 2
                ProductType:'P',
                selectedSumm: 5000,
                Inn: '6449013711',
                FIO: '',
                bankInfo: {
                    value: '',
                    data: {
                        bic: '',
                        inn:'',
                        kpp:'',
                        correspondent_account:'',
                    },
                    AccountNumber:'',
                }
            },
            err: {
                EDUCATION: '',
                EXPER_RADIO: '',
                TYPE_OF_ORGANIZATION: '',
                TYPE_OF_ACTIVITY: '',
                TYPE_OF_EMPLOYMENT: '',
                MARITAL_STATUS: '',
                WHERE_T_FIND_US: '',
                passport1: '',
                passport2: '',
                passport3: '',
                selfImage: '',
                BankAccount: '',
                Inn: '',
                AccountNumber:'',
                correspondent_account:'',
                kpp:'',
                innBank:'',
                bic:'',
            },
            foto: {
                passport1: '',
            },
            fotoID:{
                passport1: '',
            },
            dealInfo: {
                paymentDate: '',
                paymentTerm: '',
                ProductType: '',
                paymentSumm: '',
                smsConfCode:'',
                confCodeCorrect:false,
                isAgree:true,
                isAgreeRequest:false,
                isAgreeIU:false,
                isGeneralConditions: false,
                isTermsOfProvision: false,
                isInformationAboutConditions: false,
                pod_ft: true,
                pod_ft1: true,
                pod_ft2: true,
                pod_ft3: true,
                pod_ft4: true,
                pod_ft5: true,
            },
			DlProductTypeID:0,
            isLoading: true,
            requestGuid: '',
            iterationSave:0,
            formStatus: 0,
            formStep: 0,
            formErrMsg: '',
            demand: 0,
            GUUID :'',
            pdlLimit: 15000,
            loading: true,
            suggestions: [
                {
                    data: []
                }
            ],
            psk: [],
			ProductType_O:true,
			ProductType_T:true,
			ProductType_P:true,
			iu_url:'/public/pdl_IU?request=',
			anketa_url:'/public/pdlForm?request=',
        }
    },
    methods: {
        insuranceOff() {
            this.$palantirLnd.trackWebAction(105, '', localStorage.getItem('sessionLnd'));
            this.fromStep(0);
            this.forSend.insurance = false;
        },
        fromStep(currentStep) {
            switch (currentStep) {
                case 0:
                    this.loading = true;
					this.$external.universalRequest({
						action: 'GetBankInfo',
            externalType: 'zita',
						//wistest:true,
						GUID:localStorage.getItem('confirmationid') || '',
					}).then((res) => {
						if(this.isDebug){console.log('then');console.log(res)}
						localStorage.setItem('user_data_FIO', res.FIO);
						localStorage.setItem('user_data_bank_account_number', res.Account);
						localStorage.setItem('user_data_bank_bank_name', res.BankName);
						localStorage.setItem('user_data_bank_bik', res.BIK);
						this.forSend.bankInfo.data.bic   = localStorage.getItem('user_data_bank_bik') || ''
						this.forSend.bankInfo.value      = localStorage.getItem('user_data_bank_bank_name') || ''
						this.forSend.FIO                 = localStorage.getItem('user_data_FIO') || ''
						this.forSend.bankInfo.AccountNumber = localStorage.getItem('user_data_bank_account_number') || ''
					}).catch((res) => {
						if(this.isDebug){
              if ( res.errorCode > 0 || res.errorCode !== undefined ) {
                alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
              }
              console.log('catch');
              console.log(res)
            }
						this.formStep = 0
						localStorage.setItem('bakformStep', 0)
					}).finally(() => {
						this.loading = false;
					})
                    break;
                case 1:
					if (this.dealInfo.pod_ft) {
                        //если зажали галочку на все согласны
                        this.$palantirLnd.trackWebAction(106, '', localStorage.getItem('sessionLnd'));
						this.dealInfo.paymentSumm = this.forSend.selectedSumm
						localStorage.setItem('bakselectedSumm',this.forSend.selectedSumm);
						//localStorage.setItem('user_data_period',this.forSend.ProductType);
						for( let i = 0; i < this.psk.length; i++ ){
							if( Number(this.forSend.selectedSumm) == Number(this.psk[i].Amount) && this.psk[i].ProductType == this.forSend.ProductType) {
								if(this.isDebug){console.log('pdl fined',this.psk[i])}
								this.dealInfo.paymentTerm = this.psk[i].PeriodType
								if      (this.psk[i].ProductType == "O"){ localStorage.setItem('bakProductType', "O"); this.dealInfo.paymentTerm = 7  }
								else if (this.psk[i].ProductType == "T"){ localStorage.setItem('bakProductType', "T"); this.dealInfo.paymentTerm = 14 }
								else if (this.psk[i].ProductType == "P"){ localStorage.setItem('bakProductType', "P"); this.dealInfo.paymentTerm = 30 }
								let  a, b, c, d, e
								a = new Date();
								b = a.setDate(4)
								c = a.setDate(5)
								d = c-b
								e = new Date(( (+ new Date()) + (this.dealInfo.paymentTerm*d) ))
								this.dealInfo.paymentDate = e.toJSON()
								this.loading = true;
								this.$external.universalRequest({
                  externalType: 'zita',
									action: 'saveSKPDL',
									//wistest:true,
									guid_client: localStorage.getItem('confirmationid') || '',
									datajs:JSON.stringify({
										UserID:        localStorage.getItem('UserID'),
										SessionKey:    localStorage.getItem('SessionKey'),
										confirmationid:localStorage.getItem('confirmationid') || '',
										BankName:      this.forSend.bankInfo.value,
										Account:       this.forSend.bankInfo.AccountNumber,
										BIK:           this.forSend.bankInfo.data.bic,
										Amount:        this.forSend.selectedSumm,
										ProductTypeID: this.psk[i].DlProductTypeID,
									})
								}).then((res) => {
								// { "RequestID": "8-1670286782-731", "Response": { "ExecutionResult": [], "ExecutionError": { "ExecutionErrorCode": 3, "ExecutionErrorMessage": "Уже есть заявка" }},"Error": { "ErrorCode": 0 }, "sys_name": "lk_client_2" }
									this.loading = false;
									this.GUUID = res.GUUID
									localStorage.setItem( 'user_data_guidDemand', res.GUUID );
									this.$palantirLnd.trackWebAction(103, '', localStorage.getItem('sessionLnd'));
									if(this.isDebug){console.log('then');console.log(res)}
									this.formStep = 2
									localStorage.setItem('bakformStep', 2)
								}).catch((res) => {
									this.formStep = 1
									localStorage.setItem('bakformStep', 1)
									this.loading = false;
									this.$palantirLnd.trackWebAction(104, JSON.stringify(res), localStorage.getItem('sessionLnd'));
									if ( this.isDebug ) {
                    if ( res.errorCode > 0 || res.errorCode !== undefined ) {
                      alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
                    }
                    console.log('catch');
                    console.log(res)
                  }
									if(
										typeof res == 'object'
										&& res.errorMsg !== undefined
										&& res.errorCode > 0
										&& typeof res.errorMsg == 'string'
									){
                    alert(res.errorMsg)
                  }
									this.formStep = 2
									localStorage.setItem('bakformStep', 2)
								}).finally(() => {
									this.loading = false;
								})
							}
						}
                    } else {
                        return false;
                    }
					this.formStep = 2
					return false;
					break;
                case 2:
					/**/
					if(this.dealInfo.smsConfCode.length > 0){
						this.loading = true;
						this.$external.universalRequest({
							action: 'pdl2_CheckCode',
              externalType: 'zita',
							Code:this.dealInfo.smsConfCode,
							guid: localStorage.getItem('confirmationid') || '',
						}).then((res)=>{
							if(this.isDebug){console.log('then');console.log(res);}
							if(res.succses == "false"){
								this.formStep = 2
								localStorage.setItem('bakformStep', 2)
								alert("Не верный SMS код.")
								return false
							}
							this.$palantirLnd.trackWebAction(155, JSON.stringify(res), localStorage.getItem('sessionLnd'));
							this.$store.dispatch('resetState');
							this.formStep = 3
							localStorage.setItem('bakformStep', 3)
						}).catch((res)=>{
							if ( this.isDebug ) {
                console.log('catch');
                console.log(res);
                if ( res.errorCode > 0 || res.errorCode !== undefined ) {
                  alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
                }
              }
							this.$palantirLnd.trackWebAction(156, JSON.stringify(res), localStorage.getItem('sessionLnd'));
							this.formStep = 2
							localStorage.setItem('bakformStep', 2)
						}).finally(()=>{
							this.loading = false;
						})
					} else {
						alert('Введите код подтверждения');
						return false
					}
					break;
                case 3:break;
                default:
                    if(this.isDebug){console.log('default');console.log(currentStep);}
                    break;
            }
            this.formStep +=1 ;
			localStorage.setItem('bakformStep', this.formStep)
        },
		resendingSms(){
			this.loading = true;
			this.$external.universalRequest({
        externalType: 'zita',
				action: 'pdl_ResendingSms',
				type: 'pdl',
				GUID: localStorage.getItem('confirmationid') || '',
			}).then((res)=>{
				if(this.isDebug){console.log('then');console.log(res);}
				this.$palantirLnd.trackWebAction(155, JSON.stringify(res), localStorage.getItem('sessionLnd'));
			}).catch((res)=>{
				if ( this.isDebug ) {
          console.log('catch');
          console.log(res);
          if ( res.errorCode > 0 || res.errorCode !== undefined ) {
            alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
          }
        }
				this.$palantirLnd.trackWebAction(156, JSON.stringify(res), localStorage.getItem('sessionLnd'));
			}).finally(()=>{
				this.loading = false;
			})
		},
        saveData() {
            return new Promise((resolve, reject) => {
                this.$external.universalRequest({
                  action: 'saveAnketaPDL',
                  externalType: 'zita',
                  demand: this.demand,
                  data:{...this.fotoID, ...this.forSend}
                }).then(res => {
					if(this.isDebug){console.log('then');console.log(res);}
                    resolve(res);
                }).catch(res => {
                  if ( this.isDebug ) {
                    console.log('catch');
                    console.log(res);
                    if ( res.errorCode > 0 || res.errorCode !== undefined ) {
                      alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
                    }
                  }
                    reject(res);
                });
            });
        },
        confirmForm() {
            if (this.checkBank()) {
                this.formStatus = 1;
                //по другому не смог запустить их строго по очереди
                this.sendPassport1()
                .then(this.saveData)
                .then((res) => {
					if(this.isDebug){console.log('then');console.log(res);}
                    if (res.Success == 1) {
                        this.formStatus = 3;
                        this.GUUID = res.GUUID;
                        this.dealInfo.paymentDate = res.PayDt;
                        this.dealInfo.paymentTerm = res.period;
                        this.$palantirLnd.trackWebAction(152, '', localStorage.getItem('sessionLnd'));
                    } else {
                        this.$palantirLnd.trackWebAction(153, JSON.stringify(res), localStorage.getItem('sessionLnd'));
                    }
                }).catch(res => {
					if(this.isDebug){console.log('catch');console.log(res);}
                    let result = this.$myApi.parseError(res);
                    this.formErrMsg = result.ExecutionErrorMessage;
                    this.formStatus = 0;
                    this.$palantirLnd.trackWebAction(154, JSON.stringify(res), localStorage.getItem('sessionLnd'));
                });
            }else {
                this.$palantirLnd.trackWebAction(113, '', localStorage.getItem('sessionLnd'));
            }
        },
        checkDesign() {
            this.$palantirLnd.trackWebAction(102, '', localStorage.getItem('sessionLnd'));
			this.loading = false;
        },
        onSelected(item) { this.forSend.bankInfo = item.item; },
        getSuggestionValue(suggestion) { return suggestion.item.value; },
        finishPdl(){
			/** /
            if (this.dealInfo.isAgreeIU
              && this.dealInfo.isAgreeRequest
              && this.dealInfo.isAgree
              && this.dealInfo.isGeneralConditions
              && this.dealInfo.isTermsOfProvision
              && this.dealInfo.isInformationAboutConditions
            ) {
			/**/
				if(!this.dealInfo.isAgree){return false}
                this.loading = true;
                this.$external.universalRequest({
                  action: 'pdl2_CheckCode',
                  externalType: 'zita',
                  Code:this.dealInfo.smsConfCode,
                  guid: localStorage.getItem('confirmationid') || '',
                }).then((res)=>{
					if(this.isDebug){console.log('then');console.log(res);}
					//setTimeout(()=>{ this.$router.push('/'); }, 500);
					// {"RequestID":"7-1665570361-501","Response":{"ExecutionError":{"ExecutionErrorCode":-502,"ExecutionErrorMessage":"."}},"Error":{"ErrorCode":0},"sys_name":"lk_client_1"}
					if(res.succses == "false"){
						alert("Не верный SMS код.")
						this.formStep = 2
						localStorage.setItem('bakformStep', 2)
						return false
					} else if(res.succses == "true"){

					} else if(this.isDebug){console.log('Erroe request SMS code.');}
                    this.$palantirLnd.trackWebAction(155, JSON.stringify(res), localStorage.getItem('sessionLnd'));
                    this.$store.dispatch('resetState');
                    setTimeout(()=>{ this.$router.push('/'); }, 10000);
                }).catch((res)=>{
                  if ( this.isDebug ) {
                    console.log('catch');
                    console.log(res);
                    if ( res.errorCode > 0 || res.errorCode !== undefined ) {
                      alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
                    }
                  }
                  this.formStep = 2
                  localStorage.setItem('bakformStep', 2)
                  this.$palantirLnd.trackWebAction(156, JSON.stringify(res), localStorage.getItem('sessionLnd'));
                }).finally(()=>{
                  this.loading = false;
                })
          this.formStep +=1
          localStorage.setItem('bakformStep', this.formStep)
          /** /} else {
                this.$palantirLnd.trackWebAction(157, '', localStorage.getItem('sessionLnd'));
                alert('Для продолжения, подтвердите достоверность введенных данных и согласия')
            }
			/**/
        }
    },
    computed: {
        progress() { return Math.round(this.iterationSave / 7 * 100); },
        minLimit() {
            if (this.psk.length == 0) return 0;
            let minValue = this.pdlLimit;
            this.psk.forEach(item => {
                if (parseInt(item.Amount, 10) < minValue) {
                    minValue = parseInt(item.Amount, 10)
                }
            });
            return minValue;
        },
		/*pdlLimit(){
            if (this.psk.length == 0) return 100;
            let pdlLimit = 100;
            this.psk.forEach(item => {
                if (parseInt(item.Amount, 10) > pdlLimit) { pdlLimit = parseInt(item.Amount, 10) }
            });
            return pdlLimit;
		},*/
        computedPay() {
            if (this.psk.length == 0) return {FixedPay: 0}
            let result = this.psk.find((item) => {
                return parseInt(item.Amount, 10) == parseInt(this.forSend.selectedSumm, 10) && item.ProductType == this.forSend.ProductType
            });
            if (result == undefined || result.FixedPay == undefined) {if(this.isDebug){console.log('computedPay',result);}return {FixedPay: 0}}
            return result;
        }
    },
    watch: {
        'dealInfo.isAgreeIU'(value) {
            if (!value) {
                this.dealInfo.isAgree = false;
            }
        },
        'dealInfo.isAgreeRequest'(value) {
            if (!value) {
                this.dealInfo.isAgree = false;
            }
        },
        'dealInfo.isGeneralConditions'(value) {
            if (!value) {
                this.dealInfo.isAgree = false;
            }
        }, 'dealInfo.isTermsOfProvision'(value) {
            if (!value) {
                this.dealInfo.isAgree = false;
            }
        }, 'dealInfo.isInformationAboutConditions'(value) {
            if (!value) {
                this.dealInfo.isAgree = false;
            }
        },
        'dealInfo.isAgree'(value) {
            if (value == true) {
                this.dealInfo.isAgreeIU =
                  this.dealInfo.isAgreeRequest =
                    this.dealInfo.isGeneralConditions =
                      this.dealInfo.isTermsOfProvision =
                        this.dealInfo.isInformationAboutConditions = true;
            } else {
                this.dealInfo.isAgreeIU =
                  this.dealInfo.isAgreeRequest =
                    this.dealInfo.isGeneralConditions =
                      this.dealInfo.isTermsOfProvision =
                        this.dealInfo.isInformationAboutConditions = false;
            }
        },
		'forSend.ProductType'(){
			if(this.isDebug){console.log(this.forSend.ProductType)}
			let result = this.psk.find((item) => { return parseInt(item.Amount, 10) == parseInt(this.forSend.selectedSumm, 10) && this.forSend.ProductType == item.ProductType });
			if(typeof result != 'undefined'){
				this.DlProductTypeID = result.DlProductTypeID
			}
			localStorage.setItem('bakProductType', this.forSend.ProductType)
		},
        'forSend.selectedSumm'(value) {
			if(this.isDebug){console.log('watch->forSend.selectedSumm 1 ', this.forSend.selectedSumm, value)}
			//localStorage.setItem('bakselectedSumm',this.forSend.selectedSumm)
			let p_o, p_t, p_p
			for( let i = 0; i < this.psk.length; i++){
				if(parseInt(this.psk[i].Amount, 10) == parseInt(this.forSend.selectedSumm, 10)){
					if(this.psk[i].ProductType == 'O'){p_o = this.psk[i]}
					if(this.psk[i].ProductType == 'T'){p_t = this.psk[i]}
					if(this.psk[i].ProductType == 'P'){p_p = this.psk[i]}
				}
			}
			if(typeof p_o == 'object'){this.ProductType_O = true} else {this.ProductType_O = false}
			if(typeof p_t == 'object'){this.ProductType_T = true} else {this.ProductType_T = false}
			if(typeof p_p == 'object'){this.ProductType_P = true} else {this.ProductType_P = false}
			let reg = false
			switch(this.forSend.ProductType){
				case 'O':if(!this.ProductType_O){reg = true;}break;
				case 'T':if(!this.ProductType_T){reg = true;}break;
				case 'P':if(!this.ProductType_P){reg = true;}break;
			}
			if(reg){
				     if(this.ProductType_O) {this.forSend.ProductType = 'O'}
				else if(this.ProductType_T) {this.forSend.ProductType = 'T'}
				else if(this.ProductType_P) {this.forSend.ProductType = 'P'}
			}
			let result = this.psk.find((item) => { return parseInt(item.Amount, 10) == parseInt(this.forSend.selectedSumm, 10) && this.forSend.ProductType == item.ProductType });
			if(this.isDebug){console.log('watch->forSend.selectedSumm 2 ', result)}
			if( typeof result == 'undefined' && this.psk.length != 0 ){ result = this.psk[0] }
			if( typeof result != 'undefined' ){
				this.DlProductTypeID = result.DlProductTypeID
			}
		},
        'forSend.bankInfo.value'(value) {
            if (value.length > 3) {
                this.$myApi.singleRequest({action: 'Dadata_getBank', query: value}).then((res) => { this.suggestions[0].data = res.suggestions; })
            }
        },
        'dealInfo.pod_ft'(value) {
            if (value) {
                this.dealInfo.pod_ft1 =
                  this.dealInfo.pod_ft2 =
                    this.dealInfo.pod_ft3 =
                      this.dealInfo.pod_ft4 =
                        this.dealInfo.pod_ft5 = true;
            } else {
                this.dealInfo.pod_ft1 =
                  this.dealInfo.pod_ft2 =
                    this.dealInfo.pod_ft3 =
                      this.dealInfo.pod_ft4 =
                        this.dealInfo.pod_ft5 = false;
            }
        },
        'dealInfo.pod_ft1'(value) {
            if (!value) {
                this.dealInfo.pod_ft = false;
            }
        },
        'dealInfo.pod_ft2'(value) {
            if (!value) {
                this.dealInfo.pod_ft = false;
            }
        },
        'dealInfo.pod_ft3'(value) {
            if (!value) {
                this.dealInfo.pod_ft = false;
            }
        },
        'dealInfo.pod_ft4'(value) {
            if (!value) {
                this.dealInfo.pod_ft = false;
            }
        },
        'dealInfo.pod_ft5'(value) {
            if (!value) {
                this.dealInfo.pod_ft = false;
            }
        }
    },
    mounted: function () {
        localStorage.removeItem('redirect');

			if(localStorage.getItem('pdlLimit')!== null){
				let limit = parseInt(localStorage.getItem('pdlLimit'),10);
				if (!isNaN(limit)){
					this.pdlLimit = limit;
					// this.forSend.selectedSumm = limit
				}
			}


        this.$palantirLnd.trackWebAction(101, '', localStorage.getItem('sessionLnd'));
        this.demand = parseInt(localStorage.getItem('demand'), 10);
        this.$myApi.singleRequest({
            action: 'getPdlPsk2',
            type: 'pdl'
        }).then((res) => {
			if(this.isDebug){console.log('then');console.log(res);}
            this.psk = res.sort(function (a, b) { return a.Amount - b.Amount; });
			if(this.psk.length != 0){
				if(this.isDebug){console.log('limit set');console.log(this.minLimit)}
				// this.forSend.selectedSumm = this.minLimit + 1000;
				setTimeout( ()=>{
					if(this.isDebug){console.log('then->minLimit',localStorage.getItem('bakselectedSumm'));}

					if(this.isDebug){console.log('dd',Number(localStorage.getItem('bakselectedSumm')));}
					if(this.isDebug){console.log('dd2',isNaN(Number(localStorage.getItem('bakselectedSumm'))));}
					console.log(!isNaN(Number(localStorage.getItem('bakselectedSumm'))))
					if(localStorage.getItem('bakselectedSumm') !== null
						&& !isNaN(Number(localStorage.getItem('bakselectedSumm')))){
						this.forSend.selectedSumm = localStorage.getItem('bakselectedSumm')
					} else {
						this.forSend.selectedSumm =  this.minLimit;
					}
					if(localStorage.getItem('bakProductType')){
						this.forSend.ProductType = localStorage.getItem('bakProductType')
					}
				}, 200 )
			}
        }).catch((res) => {
            if(this.isDebug){console.log('catch');console.log(res);}
        });
        this.$myApi.singleRequest({
            "action":"loanForm",
            "type":"getRef"
        }).then((res)=>{
			if(this.isDebug){console.log('then');console.log(res);}
            this.$store.dispatch('saveRef', res);
        }).catch((res)=>{ if(this.isDebug){console.log('catch');console.log(res);} });
        this.checkDesign();
		//localStorage.getItem('')
		//this.formStep   = localStorage.getItem('bakformStep')     || 0
		//this.formStep   = 0
		this.formStep     = localStorage.getItem('bakformStep')     || 0
		if(this.formStep > 2){this.dealInfo.isAgree = true}
		this.ProductType                 = localStorage.getItem('bakProductType')  || 'P'
		this.forSend.ProductType         = localStorage.getItem('bakProductType')  || 'P'
		if      (this.forSend.ProductType == "O"){ this.dealInfo.paymentTerm = 7  }
		else if (this.forSend.ProductType == "T"){ this.dealInfo.paymentTerm = 14 }
		else if (this.forSend.ProductType == "P"){ this.dealInfo.paymentTerm = 30 }
		//this.forSend.selectedSumm        = localStorage.getItem('bakselectedSumm') || 0
		this.forSend.bankInfo.data.bic   = localStorage.getItem('user_data_bank_bik') || ''
		this.forSend.bankInfo.value      = localStorage.getItem('user_data_bank_bank_name') || ''
		this.forSend.FIO                 = localStorage.getItem('user_data_FIO') || ''
		this.forSend.bankInfo.AccountNumber = localStorage.getItem('user_data_bank_account_number') || ''
		//this.forSend.bankInfo.data.inn =
		//this.forSend.bankInfo.data.kpp =
		/**/
		let  a, b, c, d, e, g
		g = localStorage.getItem('user_data_bank_paydate')
		if(/^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i.test(g)){
			a = new Date(g)
		}else{
			a = new Date()
		}
		b = a.setDate(4)
		c = a.setDate(5)
		d = c-b
		e = new Date(( (+ new Date()) + (this.dealInfo.paymentTerm*d) ))
		this.dealInfo.paymentDate = e.toJSON()
		if( typeof localStorage.getItem( 'user_data_guidDemand') == 'string' ){
			this.GUUID = localStorage.getItem( 'user_data_guidDemand')
		}
		/**/


    },
  }
</script>

<style scoped>
    .flex{justify-content: space-between}
    .fotolabel{border:1px solid #0f5740;border-radius: 4px;padding: 4px;display: block;margin: 2vw 0;}
    #formGlobalErr {color: coral}

    #formStep1 {}
    #formStep1 .subBox {}
    .bigNumber {font-weight: bold}
    #formStep1 .subBox:last-child {display: flex;align-items: center;justify-content: space-evenly;flex-wrap: wrap}
    #formStep1 .subBox:first-child > p {display: flex;align-items: center;justify-content: space-between}
    #formStep1 .subBox:first-child .limitRange {display: flex;align-items: center;justify-content: space-between}
    #withOutInsurance {}
    #withOutInsurance p {margin: 0;}
    .readOnly {pointer-events: none;opacity: 0.3}
    .sugsItem {}
    .sugsItem p:first-child {}
    .sugsItem p:last-child {}
    #dealInfo{display: flex;align-items: center;justify-content: space-around;background: white;width: 100%;margin: 0 auto;padding: 3.4vw 0;border: 1px solid #c2c2c2;border-radius: 5px}
    #dealInfo > div{width: 30%;text-align: center}
    /deep/ #isAgree label{justify-content: space-between;
    text-align: end;
    height: 14vw;}

    #isAgree{width: 100%;margin: 7vw auto; }
    .inputBoxWrap{width:100%;}
    .long{width: 100%;}

    ul li div {display: flex;align-items: center;margin: 10px 0;text-align: left}
    ul li div input {margin-right: 10px}
    ul li div p {margin: 0;padding: 0;}
    ul li > ul {margin-left: 30px;}
    ul li > ul > li {list-style: circle}
    /deep/ #asp .visualCheckBox {border: 1px solid #c2c2c2;}
    /deep/ #asp .visualCheckBox.active {stroke: #43AC35}
    #isAgree > div {display: flex;align-items: center;margin: 10px 0;text-align: left}
    #isAgree > div > input {margin-right: 10px}
    #isAgree > div > p {margin: 0;padding: 0;}
    #pdlPeriod{}
    #pdlPeriod > div{margin-bottom: 2vw;}

    @media screen and (min-width: 760px) {
        .flex{display:flex;}
        #fotowrap {display: flex;align-items: flex-start;justify-content: space-between;flex-wrap: wrap;}
        #fotowrap > div {width: 49%;}
        .fotolabel{margin: 2vw 0; min-height: 16vw;}
        .fotolabel > p{height: 3vw;}
        #formStep1 {display: flex;align-items: stretch;justify-content: space-between;}
        #formStep1 .subBox:first-child {width: calc(70% - 40px);}
        #formStep1 .subBox:last-child {width: calc(28% - 40px);}
        #withOutInsurance {display: flex;align-items: center;justify-content: flex-end;padding-bottom: 1vw}
        #withOutInsurance p {padding: 0 2.7vw;font-size: 1.2vw}
        #dealInfo{display: flex;align-items: center;justify-content: space-between;background: white;width: 100%;margin: 0 auto;padding: 3.4vw 0;border: 1px solid #c2c2c2;border-radius: 5px}
        #dealInfo > div{width: 30%;text-align: center}
        #isAgree{margin: 2vw auto}
        .inputBoxWrap{width: 49.5%;}
        .long{width: 100%;}
        #BankAlign > div {display: flex;align-items: center;justify-content: space-between;}
        #BankAlign > div > .inputBox {width: 49%}
        #BankAlign > div > p{width: 49%; }
        /deep/ #isAgree label{justify-content: flex-start;height: 3vw;}

    }

    @media screen and (min-width: 1200px){
        .fotolabel{margin: 10px 0; min-height:151px;}
        .bigNumber {font-size: 30px;}
        #withOutInsurance {padding-bottom: 25px}
        #withOutInsurance p {padding: 0 26px 0 0;font-size: 18px}
        .sugsItem {padding: 5px 10px;}
        .sugsItem p {margin: 0;}
        .sugsItem p:first-child {font-size: 14px}
        .sugsItem p:last-child {font-size: 12px;color: #c2c2c2;}

        #dealInfo{display: flex;align-items: center;justify-content: space-between;background: white;width: 70%;margin: 0 auto;padding: 20px 0;border: 1px solid #c2c2c2;border-radius: 5px}
        #dealInfo > div{width: 30%;text-align: center}
        #dealInfo > div > p:first-child{}
        #dealInfo > div > p:last-child{}

        #isAgree{width: 70%;margin: 20px auto}


        #confCodeWrap{width: 220px;text-align: center;position: relative;right: 0;}
        #confCodeWrapAlign{
			display: flex;align-items: center;justify-content: flex-end;margin: 0 0 30px;
			    /*
				position: absolute;
				background: white;
				left: 52%;
				padding: 2rem;
				border-radius: 1rem;
				border: 1px solid #00000070;
				margin-bottom: 2rem;
				*/
		}
        /deep/ #isAgree label {justify-content: flex-start;height: auto;}
        /deep/ #isAgree label p {margin: 0 0 0 10px;}
        #isAgree a {margin: 0 0 10px 10px;display: inline-block}
        #pdlPeriod{display: flex;align-items: center}
        #pdlPeriod > div{margin-bottom: 10px;margin-right: 10px;}
    }
    .submitButton.active{background: #c2c2c2}
    #pdlWrap {margin-bottom: 6vh;}
</style>
